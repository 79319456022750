.headStyle {
    color: #323643 !important;
    font-size: 18px !important;
    font-weight: 900 !important;
    margin-bottom: 0px !important;
}
.circleWidth{
    width: 100px;
    height: 40px;
}
.searchTitleClass{
    color: #323643 !important;
    font-size: 1rem !important;
}
.serchCard{
    height:50px !important;
}
.serchCardNew{
    height:50px !important;
    /* background-color:#d8d3d3 !important;
    width: 100% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important; */
}
@media only screen and (max-width:600px){
    .circleWidth{
        width: 100px;
        height: 38px;
    }  
}
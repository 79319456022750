.icon-circle {
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 62px;
	left: 40% !important;
	margin-top: -1% !important;
}
.circleSetJob {
	margin-left: 34% !important;
	margin-top: -5%;
	margin-bottom: 13% !important;
  }
.modal-center{
	margin-left: 26%;
    margin-top: 3%;
}
.iconShift{
	margin-left: 45% !important;
}
.mymodal-close{
	margin-left: -12% !important;
}
.modal-content{
	border-radius: 14px;
    width: 62%;
    margin-left: 17%;
}
@media only screen and (max-width: 768px) {
	.icon-circle {
		left: 40% !important;
		margin-top: -3% !important;
	}
	.modal-center{
		margin-left: 0%;
		margin-top: 12rem;
	}
	.circleSetJob {
		margin-left: 36% !important;
	  }
	  .modal-content{
		border-radius: 14px;
		width: 62%;
		margin-left: 11%;
		margin-right: 0%;
	}
}

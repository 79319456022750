.badgeFontSize{
    font-size: 12px;
    font-weight: normal;
}
.textExpiry{
    background-color: #8B9CA8 !important;
}
.cardExpiry{
    background-color: #948f8f1c !important;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
}
/* ----------------------------------------------------------------------------------
                For Fixing the image size in the cards
-------------------------------------------------------------------------------------*/
.company-image{
    position: absolute;
    display: block;
    max-width: 80%;
    max-height: 100%;
    left: 0;
    right: 0;
    top: 0px;
    object-fit: cover;
    bottom: 0;
    margin: auto;
}
.jobTitle{
    font-size: 17px !important;
    font-weight: bolder;
}
.text-color{
    color: #00cc99 !important;
}
@media only screen and (min-width:100px) and (max-width:320px){
    .mobileTextAlign{
        font-size: 8px !important;
    }
    .badgeFontSize{
        font-size: 9px;
        font-weight: normal;
    }
}
@media only screen and (min-width:322px) and (max-width:600px){
    .mobileTextAlign{
        font-size: 9px !important;
    }
    .badgeFontSize{
        font-size: 9px;
        font-weight: normal;
    }
}
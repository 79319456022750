.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle{
    background-color: #00cc99 !important;
    color: #fff !important;
}
.btn-outline-secondary:hover {
    color: #fff;
    background-color: #00cc99;
}
.interestBack{
    background-image: url("../assets/iconwallpaper.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0.7;
}



.cardMiddle{
    margin-top: 3%;
}
.comName{
    font-size: 18px !important;
}

/* For card border radius */
.rounded{
    border-radius: 15px !important;
}

@media only screen and (max-width:768px){
    .cardMiddle{
        margin-top: 42%;
    }
}


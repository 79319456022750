/* Universal loader code */
.loadingText{
    color: #fff!important;
    font-size: 16px !important;
}
.loaderWhite{
    width: 17%;
}
/* Loader for the About Me Page */
.loading-aboutme{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 11%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for the Applied Jobs Page */
.loading-applied{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 15%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for the Blog/ Blog Detail Page */
.loading-blog{
    width: 18%  !important;
    margin-left: 42% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for the change language page */
.loading-languages{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 17%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for the company profile page */
.loading-companyprofile{
    width: 17%  !important;
    margin-left: 30% !important;
    left: 11% !important;
    background: no-repeat center center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for the company review  */
.loading-review{
    margin-left: 44% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 15%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for Company Feed Back page */
.loading-feedback{
    width: 18%  !important;
    margin-left: 42% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* loader for Edit Profile */
.loading-edit{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 15%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for Education Detail Page */
.loading-educationdetail{
    margin-left: 38% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 19%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for freshers tab */
.loading-fresher{
    margin-left: 40% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 18%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader for Interest page */
.loading-interest{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 17%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for an interview tips */
.loading-interviewtips{
    width: 16%  !important;
    margin-left: 38% !important;
    left: 5% !important;
    background: no-repeat center center;
    height: 200px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for an map jobs */
.loading-map{
    width: 16%  !important;
    margin-left: 38% !important;
    left: 5% !important;
    background: no-repeat center center;
    height: 200px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/* Loader For search Jobs page */
.loading-search{
    margin-left: 44% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 15%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
  }
  /* Loader for the profile page */
  .loading-profile{
    width: 18%  !important;
    margin-left: 42% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for Notification */
.loading-notification{
    margin-left: 42% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 17%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for My Jobs */
.loading-myjobs{
    width: 16%  !important;
    margin-left: 40% !important;
    left: 3% !important;
    background: no-repeat center center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}

/*  ForMy Jobs page */
.loading-jobs{
    margin-left: 43% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 15%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/* Loader for the Job List page */
.loading-joblist{
    margin-left: 41% !important;
    left: 0% !important;
    background: no-repeat center center;
    height: 100px;
    width: 22%;
    position: fixed;
    z-index: 1000;
    top: 40%;
    margin: -25px 0 0 -25px;
}
/*========================================================================================
                         For making the Mobile Responsive 
==========================================================================================*/

@media only screen and (max-width:768px){
    /* Loader for the About Me Page */
    .loading-aboutme{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }

    /* Loader for the Applied Jobs Page */
    .loading-applied{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the Blog/ Blog Detail Page */
    .loading-blog{
        width: 34%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the change language page */
    .loading-languages{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* loader for the company profile page*/
    .loading-companyprofile{
        width: 34%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the company review  */
    .loading-review{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for comapny feedback page */
    .loading-feedback{
        width: 34%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the Edit Profile */
    .loading-edit{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the Education detail page */
    .loading-educationdetail{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for Fresher tab */
    .loading-fresher{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for Interest page */
    .loading-interest{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for an interview tips */
    .loading-interviewtips{
        width: 32%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for an map jobs */
    .loading-map{
        width: 32%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for search job page */
    .loading-search{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the profile page */
    .loading-profile{
        width: 34%  !important;
        margin-left: 34% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for the notification */
    .loading-notification{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* Loader for My Jobs Page */
    .loading-myjobs{
        width: 33%  !important;
        margin-left: 28% !important;
        left: 6% !important;
        background: no-repeat center center;
        height: 100px;
        width: 100%;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* For my Jobs page */
    .loading-jobs{
        width: 33%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
    /* For job list page  */
    .loading-joblist{
        width: 37%  !important;
        margin-left: 32% !important;
        left: 0% !important;
        background: no-repeat center center;
        height: 100px;
        position: fixed;
        z-index: 1000;
        top: 40%;
        margin: -25px 0 0 -25px;
    }
}
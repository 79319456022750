.nav-pills-tab .nav-link.active{
    background: linear-gradient(120deg, #0A9FA5 0%,#0DD3AF 100%) !important;
    color: #fff !important;
}
.alreadyExpCls{
    font-size: 16px !important;
}
.roundedField{
    border-radius: 1.2rem;
}
.eduDetail{
    color: #000 !important;
}
.inputHeadClass{
border-radius: 20px !important;
}
.jobIndustryCls{
    background: #007bff !important;
    color: white !important;
}
.currentWorkingCls{
    font-size: 15px !important;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    height: 60%;
    width: 100%;
    background: #ffffff;
}

input[type="file"] {
    display: none;
}
.dashed-success{
    border: dashed 2px #0A9FA5;
    padding: 20px;
    display: inline-block;
    color: #0A9FA5;
    border-radius: 12px;
    --dash-size: 8px;
    --gap-size: 8px;
}
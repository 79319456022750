/* Language Background page*/
.language-background{
    background-image: url('../assets/language-background.png');
    height: 910px;
    background-repeat: round no-repeat;
}
/* Language Background page*/
@media only screen and (max-width:768px){
.language-background{
    background-image: url('../assets/language-background.png');
    height: 646px;
    background-repeat: no-repeat;
    background-position-x: right;
}
}

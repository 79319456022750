.notiHeader{
    margin-left: 11% !important;

}
.shareIconClass{
  padding-left: 9% !important;
}
.pageNameSize{
  font-size: 15px !important;
}
.nameSize{
  font-size: 15px !important;
  margin-bottom: 8px !important;
}
.iconText{
  font-size: 18px !important;
}
  @media (min-width: 365px) and (max-width: 411px) {
      .notiHeader{
        margin-left: 9% !important;
      }
      .shareIconClass{
        padding-left: 13% !important;
      }
  }
  
  @media (min-width: 100px) and (max-width: 360px) {
    .notiHeader{
      margin-left: 8% !important;
    }
    .shareIconClass{
      padding-left: 13% !important;
    }
  }
  
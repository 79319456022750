.btn-box{
  border-radius:0px;
  cursor: pointer;
  width: 100%;
  padding-top: 5%;
}
.selectedNotCityCls{
color: #adb5bd !important;
}
.selectedCityCls{
color: #495057 !important;
}
.city-name{
  word-break: break-all !important;
  font-size: 16px !important;
}
.city_color{
  font-size:14px !important;
 padding-top: 2px;
 }
.fa-building, .fa-user-tie{
  font-size: 42px;
}
.icon-width{
  max-width: 20% !important;
}
.main-modal-view {
	width: 100% !important;
	left: 0% !important;
  border-radius: 20px !important;
  margin-left: 0% !important;
}
#features .icon{
  width: 0% !important;
  margin-left: 45%;
  margin-bottom: 10% !important;
}
.card-body-home{
  padding: 0.5rem !important;
}
.box-text{
  font-size: 21px;
  margin-bottom: 0px;
  margin-top: 9px;
  text-transform: uppercase;
}
.wow>h2{
  font-size: 28px
}
#post-to-action>.container-transparent{
  background-color: transparent !important;
}
#features>.container{
  background-color: transparent !important;
}
#more-features>.container{
  background-color: transparent !important;
}
#call-to-action>.container{
  background-color: transparent !important;
}
#contact>.container{
  background-color: transparent !important;
}
#footer>.container{
  background-color: transparent !important;
}
.fb-page{
  margin-left: 13% !important;
}
.z-99{
  z-index: 99;
}
.searchBox{
  background-color: rgba(255, 255, 255, 0.07);
}
.myInput-group {
  background-color: #079da3 !important;
  border: 0 !important;
}
.userIcon{
  font-size: 42px !important;
}
.icon{
  height: 3%;
  width: 3%;
  margin-right: 2%;
}
.cityDiv {
    display: inline-flex;
    overflow-x: overlay;
    overflow-y: hidden;
}
.citySelect{
  padding-bottom: 0px !important;
  padding-top: 16px !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #adb5bd !important;
  opacity: 1; /* Firefox */
}

@media only screen and (max-width: 600px) {
  .icon-width{
    max-width: 25% !important;
  }
  .cityprepend{
     height:29px; 
  }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -20px;
}
  .city-name{
    font-size: 0.8em !important;
  }
  .city_color{
    font-size:12px !important;
   margin-top: -3px !important;
  }
  .cityHeight{
    
    display: block;
    width: 100%;
    height: calc(1.0em + .65rem + 3px) !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  .fb-page{
     margin-left: 0%!important;
  }
  .myFb{
    margin-left: 13% !important;
    margin-bottom: 3% !important;
  }
  .modal-body {
    width: 100%;
    height: 320px !important;
    overflow-y: auto !important;
}
.cityDiv{
  display: contents;
}
.card-footer {
 
   background-color: #adb5bd00 !important; 
  
}
.main-modal-view{
  width:100% !important;
  left: 0%;
}
}

/* Director list */
.mb40{margin-bottom:40px;}
.team-block { margin-bottom: 20px; }
.team-content { position: absolute; background-color: rgba(17, 24, 31, 0.8); bottom: 0px; display: inline-block; width: 100%; color: #fff; padding: 30px;    left: 0; }
.team-img { position: relative; }
.team-img img { width: 100%;    height: 430px; }
.team-meta { color: #9da4aa; font-weight: 400; font-size: 16px; }
.overlay { border-radius: 0px; position: absolute; top: 0; bottom: 0; left: 0; right: 0; height: 100%; width: 100%; opacity: 0; transition: 1s ease; background-color: #11181f; }
.team-img:hover .overlay { opacity: .8; }
.team-img:hover .team-content { opacity: 0; }
.text { color: #fff; position: absolute; top: 30%; left: 30%; transform: translate(-26%, -26%); -ms-transform: translate(-26%, -26%); right: 0; font-weight: 400; font-size: 16px; }
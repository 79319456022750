#root {
  height: 100vh;
}

.containerChat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 100%
}
.border-success{
  border-color:#0DD3AF !important;
  border-radius: 5px !important;
}

.leftInnerContainerChat {
  flex: 0.5;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
}

.rightInnerContainerChat {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIconChat {
  margin-right: 5%;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButtonChat {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(to left, #00d5ae, #00b9c0) !important;
  padding-top: 9px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;

  display: inline-block;
  border: none;
  width: 20%;
}


.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 20%;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
}

.button {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #2979FF;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    width: 100%;
}

button:focus {
  outline: 0;
}
.messageBoxChat {
  background: #F3F3F3;
  border-radius: 20px !important;
  /* padding: 10px 13px 0px; */
  color: white;
  display: inline-block;
  max-width: 51%;
}

.messageTextChat {
  width: 100%;
  letter-spacing: 0;
  float: left;
  text-align: left;
  font-size: 1.1em;
  word-wrap: break-word;
}
.messageTextChatAdmin {
  width: 100%;
  letter-spacing: 0;
  float: left;
  text-align: left;
  font-size: 1.1em;
  word-wrap: break-word;
}
.messageTextChatNew {
  width: 100%;
  letter-spacing: 0;
  float: right;
  margin-right: 3% !important;
  text-align: right;
  font-size: 13px !important;
  word-wrap: break-word;
}

.messageTextChat img {
  vertical-align: middle;
}

.messageContainerChat {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 11px;
}
.messageContaineChatr{
  padding: 0 5%;
}
.sentTextChat {
  display: flex;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStartChat {
  justify-content: flex-start;
}

.justifyEndChat {
  justify-content: flex-end;
}


.messagesChat {
  /* padding: 5% 0; */
  overflow: auto;
  flex: auto;
  margin-bottom: 4%;
  height: 510px !important;
}
.textContainerChat {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  color: white;
  height: 60%;
  justify-content: space-between;
}

.activeContainerChat {
  display: flex;
  align-items: center;
  margin-bottom: 50%;
}

.activeItemChat {
  display: flex;
  align-items: center;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainerChat {
    display: none;
  }
}


@media (min-width: 320px) and (max-width: 480px) {
  .outerContainerChat {
    height: 100%;
  }
 
  .containerChat {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 100px) and (max-width: 360px) {
  .messagesChat {
    /* padding: 5% 0; */
    overflow: auto;
    flex: auto;
    margin-bottom: 4%;
    height: 545px !important;
  }
}

@media (min-width: 365px) and (max-width: 411px) {
  .messagesChat {
    /* padding: 5% 0; */
    overflow: auto;
    flex: auto;
    margin-bottom: 4%;
    height: 640px !important;
  }
}
@media (min-width: 480px) and (max-width: 1200px) {
  .containerChat {
    width: 60%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
  }
}
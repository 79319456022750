.react-datepicker__input-container>input[type="text"] {
    background-color: #fff;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    padding: 1rem!important;
}
.modalHeight{
    height: 57px;
}

.contentTop{
    position: relative;
    top: 250px;
}
.plzCodeCls{
    font-size: 20px !important;
}
.validProCLs{
  cursor: not-allowed !important;  
}
.opePhoneCls{
display: block !important;
}
.closePhoneCls{
display: none !important;
}
.genderShowCls{
    z-index: 0 !important;
}
.react-datepicker-popper {
    z-index: 9999 !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    background: linear-gradient(120deg, #0A9FA5 0%,#0DD3AF 100%) !important;
    border-color: #00cc99;
    color: #fff !important;
}
.datalist-items {
    overflow-y: scroll;
    max-height: 300px;
}
@media only screen and (max-width:768px){
    .motherName::placeholder{
        font-size: 12px;
    }
    .fatherName::placeholder{
        font-size: 12px;
    }
    
}
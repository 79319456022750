/* ----------------------------------------------------------------------------------
                For the Map button which redirect to map page 
-------------------------------------------------------------------------------------*/
.map-button {
    position: fixed;
    width: 98px;
    text-transform: uppercase;
    z-index: 999;
    top: 82%;
    left: 46%;
    border-radius: 20px;
}
.topPickSText {
    font-size: 1rem !important;
}
.checkBoxCls{
    height: 40px !important;
}
.cityValueCls{
    background: #007bff !important;
    color: white !important;
}
.chat-button {
    position: fixed;
    width: 98px;
    text-transform: uppercase;
    z-index: 999;
    top: 79%;
    left: 52%;
    border-radius: 20px;
}
.select-city{
width: 23% !important;
}
/* ----------------------------------------------------------------------------------
                For for the full time/ part time/ internship
-------------------------------------------------------------------------------------*/
.fullTypeIcon {
    font-size: 19px !important;
}
/* ----------------------------------------------------------------------------------
                For Notification Badges
-------------------------------------------------------------------------------------*/
.notibadge{
    padding-top: .7rem!important;
    padding-left: 0.3rem!important;
    padding-right: 0.3rem!important;
    font-size: 13px;
}
/* ----------------------------------------------------------------------------------
                For Filter Icons
-------------------------------------------------------------------------------------*/
.filterIcons {
    display: block;
    max-width: 60%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.searchbar-top{
    position: fixed;
    top: 6.5%;
    z-index: 999;
    width: 49.5%;
    margin-left: -0.5%;
}
.font-icon-size>a, .font-icon-size>i{
    font-size: 10px;
    color: #495057 !important;
}
.filterpadding{
    padding-top: 9px !important;
}
/* ----------------------------------------------------------------------------------
                For filter of job profile text
-------------------------------------------------------------------------------------*/
.filterText{
    font-size: 13px !important;
}
/* ----------------------------------------------------------------------------------
                For filter text in the search bar
-------------------------------------------------------------------------------------*/
.font-nonitalic{
    font-style: initial;
}
/* ----------------------------------------------------------------------------------
                For got outline grey
-------------------------------------------------------------------------------------*/
.btn-outline-grey:not(:disabled):not(.disabled).active, .btn-outline-grey:not(:disabled):not(.disabled):active, .show>.btn-outline-grey.dropdown-toggle {
    background-image: linear-gradient(0.8168rad, #0A9FA5, #0DD3AF) !important;
    color: #fff !important;
    border: 0 !important;
}
/* ----------------------------------------------------------------------------------
                For Top picks 
-------------------------------------------------------------------------------------*/
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
    background: #00b9c0 !important;
}
.carousel .control-dots .dot{
    background: #00b9c0 !important;
}
.carousel .control-dots {
    position: relative !important;
    margin: 0 !important;
    padding-left: 0px !important;
}
.carousel .slide{
    background: transparent !important;
}
/* ----------------------------------------------------------------------------------
                For input group for search input transparent 
-------------------------------------------------------------------------------------*/
.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-radius: .25rem;
    border-left: 0px;
}

.newinput{
    border-right: 0px !important;
}
.newinput::placeholder {
    color: #6c757d !important;
    opacity: 1; 
}

#Capa_1{
    margin-top: -7px;
}

/*---------------------------------------------------------------------------------
                        For bottom Navigation
---------------------------------------------------------------------------------- */
.MuiBottomNavigationAction-root.Mui-selected{
    color: #00C7B6 !important;
}
.MuiBottomNavigationAction-root{
    padding: 0px !important;
}
.carousel-control-prev-icon
{
    background-color: #495057; 
}
.carousel-control-next-icon
{
    background-color: #495057; 
}
.carousel-control-prev{
    left: -25px;
}
.carousel-control-next{
    right: -25px;
}
.inline { 
    display: inline-block; 
    margin:10px;
}
h6{
    font-size: 13px !important;
    margin-bottom: 5px !important;

}
.filter-job{
word-break: break-word;
}
/* ----------------------------------------------------------------------------------
                For Making the Resposnive 
-------------------------------------------------------------------------------------*/
@media only screen and (min-width:510px) and (max-width:768px){
    .searchbar-top{
        top: 0% !important;
        width: 100% !important;
    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -20%;
    }
    .cardBottom{
        margin-bottom: 17%;
    }
    .input-search-text{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    #basic-addon2 {
        padding-left: 0px !important;
        padding-right: 3px !important;
    }
    .newinput{
        padding:0rem !important;
    }
    .newinput::placeholder {
        font-size: 13.5px !important;
        padding-left: 8px !important;
    }
    #basic-addon2{
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
    #select1 {
        font-size: 14.5px;
    }
    .mobileSalary{
        margin-left: -5%;
    }
    .experienceText{
        font-size: 12px !important;
    }
    .filterText{
        font-size: 11px !important;
    }

    .filterBox {
        padding-left: 4px;
        padding-right: 0px;
    }
    .filterIcons {
        display: block;
        max-width: 60%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .inline {
        display: inline-block;
        margin: 0px !important; 
    }  
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}
@media only screen and (min-width:370px) and (max-width:500px) {
    .searchbar-top{
        top: 0% !important;
        width: 100% !important;
    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -20%;
    }
    .newinput::placeholder {
        font-size: 13.5px !important;
        padding-left: 4px !important;
    }
    .col-sm-6{
        padding-left:8px !important;
        padding-right:8px !important;
    }
    .inline {
        display: inline-block;
        margin: 0px !important; 
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}

@media only screen and (min-width:100px) and (max-width:320px){
    .searchbar-top{
        top: 0% !important;
        width: 94% !important;
    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -20%;
    }
    .map-button {
        left: 37%;
    }
    .chat-button {
        left: 52%;
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}
@media only screen and (min-width:325px) and (max-width:360px){
    .searchbar-top{
        width: 94% !important;
        top: 0% !important;

    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -40%;
    }
    .map-button {
        left: 37%;
    }
    .chat-button {
        left: 52%;
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}
@media only screen and (min-width:365px) and (max-width:375px){
    .searchbar-top{
        width: 96% !important;
        top: 0% !important;

    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -30%;
    }
    .map-button {
        left: 39%;
    }
    .chat-button {
        left: 52%;
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}
@media only screen and (min-width:380px) and (max-width:411px){
    .searchbar-top{
        width: 96% !important;
        top: 0% !important;

    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -20%;
    }
    .map-button {
        left: 37%;
    }
    .chat-button {
        left: 52%;
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
}

@media only screen and (min-width:412px) and (max-width:414px){
    .searchbar-top{
        width: 94% !important;
        top: 0% !important;
    }
    .center-modal{
        margin-left: 0% !important;
        width: 100%;
        margin-top: -20%;
    }
    .carousel .slider-wrapper {
        width: 90% !important;
    }
    .map-button {
        left: 39%;
    }
    .chat-button {
        left: 52%;
    }
}

/* Temporary */
.center-modal{
    margin-left: -31%;
    width: 100%;
}
/* -----------------------------------------------------------------------------------------
					THIS CSS IS FOR ALL PAGES 
--------------------------------------------------------------------------------------------*/
/* @font-face {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	src: url("https://sf.abarba.me/SF-UI-Display-Regular.otf");
} */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

/* -------------------------------- For Body -----------------------------------------------*/
body {
	background-image: url('../src/assets/img-noise.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
	margin: 0;
	/* font-family: "SF Display"; */
	font-family: 'Work Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.header-height{
	height: 39px !important;
}
.container{
	background-color:#f8f9fa!important;
}
.no-data-header {
	background: linear-gradient(0.8168rad, #0A9FA5, #0DD3AF) !important;
	height: 50px;
}
.nologin-bottom{
	border-radius: 20px !important;
}
.nologin-card{
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
}
/* --------------------------------------- For Font ----------------------------------------*/
code {
	font-family: SF Display;
}

/* --------------------------------------- Removing the scroll from every Page ----------------------------------------*/
body::-webkit-scrollbar {
	width: 0px !important;
}

/* --------------------------------------- For Every Modal ----------------------------------------*/
/* .borderRadiusModal {
	border-top-left-radius: 20px !important;
	border-top-right-radius: 20px !important;
} */
/* ---------------------------------------- FOr modal body --------------------------------------------------- */
.modal-header{
	border-top-left-radius: 14px !important;
    border-top-right-radius: 14px !important;
}
/* --------------------------------------- For Every Rounded Input ----------------------------------------*/
.rounded {
	border-radius: 20px !important;
}
.roundedForJobs {
	border-radius: 20px !important;
	font-size: 14px !important;

}
/* --------------------------------------- For Text Color ----------------------------------------*/
.text-dark{
	color: #323643 !important;
}

/* --------------------------------------- For Bg Color ----------------------------------------*/
.bg-success,
.no-data-header, .btn-success {
	background: linear-gradient(120deg, #0A9FA5 0%, #0DD3AF 100%) !important;
}

/* --------------------------------------- For Active Navigation Color ----------------------------------------*/
.active>.nav-link {
	color: #0A9FA5 !important;
}

/* --------------------------------------- For Removing Extra Padding ----------------------------------------*/
.No-Pading{
	padding-right: 0px !important;
	padding-left: 0px !important;
}
.fixed-top {
	left: 25% !important;
}
/* --------------------------------------- For Every label of input ----------------------------------------*/
.inputHeadingText {
	color: #585958;
	font-weight: 700;
}

/* --------------------------------------- For Bottom Navigation ----------------------------------------*/
.fixedTab {
	z-index: 2;
	position: fixed;
    bottom:0;
}

/* --------------------------------------- For fixing the images to see best logo in the circle ----------------------------------------*/
.search-company-image{
	position: absolute;
	display: block;
	max-width: 80%;
	max-height: 84%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.text-success-gradient{
	color: #1dc8cd !important;
}
/* --------------------------------------- For add ... in the long text in the jobs titles ----------------------------------------*/
.textLimit{
    width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
/* --------------------------------------- For making cursor pointer ----------------------------------------*/
.pointer {
    cursor: pointer;
}
/* --------------------------------------- For search bar in the Job list and map page ----------------------------------------*/
.searchBar{
    background-color:  #ffffff;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.1),0 6px 20px 0 rgba(0,0,0,0.1) !important;
    border-radius: 10px;
    padding: 12px;
}
/* --------------------------------------- For rounded ----------------------------------------*/
.rounded1{
	border-radius: 0.65rem !important;
}
/* --------------------------------------- For making the text green ----------------------------------------*/
.new-menu-icon{
	margin-left: -92%;
  }
@media only screen and (max-width:600px) {
	.textLimit {
		width: 257px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
@media (min-width: 365px) and (max-width: 411px) {
    .textLimit {
		width: 250px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (min-width: 100px) and (max-width: 360px) {
    .textLimit {
		width: 220px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
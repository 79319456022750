
.boxCenter {
    margin-left: 24% !important;
    padding-top: 23%;
    padding-bottom: 34%;
}
.slick-list{
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.fsboxCenter {
    margin-left: 8% !important;
    padding-top: 23%;
    padding-bottom: 34%;
}
.newVideo{
    text-align: -webkit-center;
}
.slick-next:before, .slick-prev:before{
    color: #00C7B6;
}
.slick-prev{
    left: -7px !important;
}
.slick-next{
    right: -7px !important;
}
.css-df17o1 {
    position: absolute;
    height: 120% !important;
    width: 100%;
    top: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color:#dddddd69 !important;
    background: #dddddd69 !important;
    z-index: 800;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1 !important;
}

.barColor{
    background: linear-gradient(120deg, #0A9FA5 0%,#0DD3AF 100%) !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.tipimage{
    height:300px !important;
    /* border-radius:40px !important; */
    border-top-left-radius: 4.25rem!important;
    border-top-right-radius: 4.25rem!important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}
.bRadius{
    border-radius:0px !important;
}
.md-cards h3 {
    padding: .5em;
    margin: 0;
}
.header-rounded{
    border-top-left-radius: 4.25rem!important;
    border-top-right-radius: 4.25rem!important;
}
.bg-lightgrey
{
    background-color: #ddd !important;
}
/* For carousel Item */
.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 62%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .4s ease-in-out;
    margin-left: 22%;
}

.nabcolor .active{
    color:#fff !important;
}

.md-img {
    pointer-events: none;
    border-radius: 8px;
}

.demo-card .mbsc-card-title {
    font-size: 14px;
    padding-top: 8px;
    margin: 0;
}

.md-rank {
    font-size: 12px;
    position: absolute;
    bottom: 8px;
    left: 8px;
}

.userScroll1{
    width: 100%;
    overflow-x: scroll !important;
}

.nav-scroll1{
    width: 320%;
}
.intercard{
    margin-left: 5% !important;
    margin-right: 5% !important;
    border-top-left-radius: 4.25rem!important;
    border-top-right-radius: 4.25rem!important;
}
.MuiBottomNavigationAction-root.Mui-selected{
    color: #00C7B6 !important;
  }
  .sharetext{
      color:#fff;
      padding: 12px;
  }
  .loginPopup{
	padding-top: 5rem!important;
}
/*========================================================================================
                         For making the Mobile Responsive 
==========================================================================================*/
  @media only screen and (max-width: 500px){
    .tabViewMobile {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .paddingCard {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
    
    }
    @media only screen and (min-width: 370px) and (max-width:500px){
        .card-title>img {
            height: 237px !important;
        }
        .sharetext{
            padding: 5px;
            font-size: 12px;
        }
        .paddingCard {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
    @media only screen and (min-width:100px) and (max-width:365px){
        .card-title>img {
            height: 145px !important;
        }
        .sharetext{
            padding: 5px;
            font-size: 12px;
        }
        .paddingCard {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
  @media only screen and (max-width:768px){

    .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform .4s ease-in-out;
        margin-left: 0%;
    }
    .itemScroll{
        overflow-y: scroll;
        height: 218px;
    }
    .videoItemScroll{
        overflow-y: scroll;
        height: 218px;
    }
    .boxCenter {
        margin-left: 0% !important;
        padding-top: 55%;
        padding-bottom: 74%;
    }
    .fsboxCenter {
        margin-left: 0% !important;
        padding-top: 45%;
        padding-bottom: 74%;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .loginPopup{
	padding-top: 0rem !important;
    }
}
@media only screen and (min-width:100px) and (max-width: 468px){
    .userScroll1{
        width: 100%;
        overflow-x: scroll !important;
    }
    .itemScroll{
        overflow-y: scroll;
        height: 175px;
    }
    .videoItemScroll{
        overflow-y: scroll;
        height: 282px;
    }
    .carousel-control-next {
       display:none;
    }
    .carousel-control-prev {
        display:none;
     }
     .navbar-light{
         background-color: #ddd !important;
         padding-bottom: 0px !important;
     }
     .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media only screen and (min-width: 470px) and (max-width: 768px){
    .userScroll1{
        width: 100%;
        overflow-x: scroll !important;
    }
    .carousel-control-next {
        display:none;
     }
     .carousel-control-prev {
        display:none;
     }
     .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
   
}
@media only screen and (max-width: 600px) {
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .boxCenter {
        margin-left: 0% !important;
        padding-top: 55%;
        padding-bottom: 74%;
    }
    .fsboxCenter {
        margin-left: 0% !important;
        padding-top: 45%;
        padding-bottom: 74%;
    }
}

/* For Card size and vertical scroll */
@media only screen and (min-width: 100px) and (max-width:320px){
    .nav-scroll1 {
        width: 680%;
    }
    .intercard {
        width: 221px !important;
        margin-left: 0% !important;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media only screen and (min-width: 330px) and (max-width:365px){
    .nav-scroll1 {
        width: 610%;
    }
    .intercard {
        width: 264px !important;
        margin-left: 0% !important;
    }
}
@media only screen and (min-width: 370px) and (max-width:400px){
    .nav-scroll1 {
        width: 580%;
    }
    .intercard {
        width: 264px !important;
        margin-left: 0% !important;
        margin-top: 6% !important;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media only screen and (min-width: 401px) and (max-width:412px){
    .nav-scroll1 {
        width: 530%;
    }
    .intercard {
        width: 300px !important;
        margin-left: 0% !important;
        margin-top: 6% !important;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media only screen and (min-width: 413px) and (max-width:500px){
    .nav-scroll1 {
        width: 490%;
    }
    .intercard {
        width: 300px !important;
        margin-left: 0% !important;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
@media only screen and (min-width: 500px) and (max-width:768px){
    .nav-scroll1 {
        width: 530%;
    }
    .intercard {
        width: 300px !important;
        margin-left: 0% !important;
    }
    .paddingCard {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .boxCenter {
        margin-left: 0% !important;
        padding-top: 55%;
        padding-bottom: 74%;
    }
    .fsboxCenter {
        margin-left: 0% !important;
        padding-top: 45%;
        padding-bottom: 74%;
    }
}
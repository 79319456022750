.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff !important;
	background-image: linear-gradient(0.8168rad, #0a9fa5, #0dd3af) !important;
	border-radius: 50px;
	margin-left: 3px;
	margin-right: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	font-size: 12px;
}
.myjobsTab{
	padding-top:4rem !important;
}
.popNoRecord {
	margin-left: -6px !important;
}


.boxCenter {
	margin-left: 24% !important;
	padding-top: 23%;
	padding-bottom: 34%;
}

.menuFixed {
	margin-top: 8%;
	margin-bottom: 0%;
}
.badge-success {
	background: linear-gradient(0.8168rad, #0a9fa5, #0dd3af) !important;
}

.exc {
	font-size: 25px !important;
	width: 9em !important;
}

.col-center {
	float: none;
	margin: 0 auto;
}

.panel-top {
	position: fixed;
	top: 61px;
	z-index: 9999;
	width: 50%;
}

.nav-scroll {
	width: 107% !important;
}

.userScroll {
	background-color: #e2e2e2fc !important;
	overflow-x: scroll !important;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
	color: black !important;
	background-color: white !important;
	border-radius: 50px;
	margin-left: 3px;
	margin-right: 3px;
	padding-top: 2px;
	padding-bottom: 2px;
	font-size: 12px;
}
@media only screen and (max-width: 768px) {
	.myjobsTab{
		padding-top:3rem !important;
	}
}
@media only screen and (max-width: 768px) {
	.boxCenter {
		margin-left: 0% !important;
		padding-top: 55%;
		padding-bottom: 74%;
	}
	.menuFixed {
		margin-top: 0%;
		margin-bottom: 17%;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
	.text {
		font-size: 13px;
	}
}

.firstSec {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.thirdSec {
	padding-left: 6px !important;
	padding-right: 2px !important;
}

.secondSec {
	padding-right: 0px !important;
	padding-left: 3% !important;
}

.MuiBottomNavigationAction-root {
	padding: 6px 1px 8px !important;
}

/* For Mobile View  */

@media only screen and (max-width: 500px) {
	.secondSec {
		padding-right: 0px !important;
		padding-left: 5% !important;
	}
	.firstSec {
		margin-right: 0px !important;
		margin-left: 0px !important;
	}
}

@media only screen and (min-width: 100px) and (max-width: 468px) {
	.nav-scroll {
		width: 218% !important;
	}
	.panel-top {
		top: 40px;
		width: 100%;
	}
	.mobile-top {
		top: 0px !important;
	}
}

@media only screen and (min-width: 414px) and (max-width: 736px) {
	.nav-scroll {
		width: 194% !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
	.loginPopup{
		padding-top: 0rem !important;
		}
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
	.nav-scroll {
		width: 264% !important;
	}
}

@media only screen and (max-width: 640px) and (min-width: 360px) {
	.nav-scroll {
		width: 206% !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
}

@media only screen and (max-width: 812px) and (min-width: 375px) {
	.nav-scroll {
		width: 190% !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
}

@media only screen and (max-width: 736px) and (min-width: 414px) {
	.nav-scroll {
		width: 170% !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
}

@media only screen and (max-width: 823px) and (min-width: 414px) {
	.nav-scroll {
		width: 170% !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
}

@media only screen and (max-width: 568px) and (min-width: 320px) {
	.nav-scroll {
		width: 192%;
	}
	.placeHolder {
		font-size: 10px !important;
	}
	.navSpace {
		margin-bottom: 4rem !important;
	}
}

.css-df17o1 {
	position: absolute;
	height: 120% !important;
	width: 100%;
	top: 0px;
	left: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	text-align: center;
	font-size: 1.2em;
	color: #dddddd69 !important;
	background: #dddddd69 !important;
	z-index: 800;
	-webkit-transition: opacity 500ms ease-in;
	transition: opacity 500ms ease-in;
	opacity: 1 !important;
}

.bg-dark2 {
	background-color: #000 !important;
	margin-left: 22% !important;
}

.css-50etie svg circle {
	animation: animation-1v1uyfb 1.5s ease-in-out infinite;
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	stroke-linecap: round;
	stroke: #000 !important;
}

.navbar-light {
	padding-bottom: 0px !important;
	padding-top: 0px !important;
}
.loginPopup{
	padding-top: 5rem!important;
}
@media only screen and (max-width: 600px) {
	.bg-dark2 {
		background-color: #000 !important;
		margin-left: 5% !important;
	}
}

@media only screen and (max-width: 500px) {
	.paddingCard {
		padding-left: 8px !important;
		padding-right: 8px !important;
	}
	.popNoRecord {
		margin-left: -3px !important;
	}
}

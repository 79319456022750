.followText
{
    font-size: 15px;
}
.width-img{
    width: 300px;
    margin-left: -19px;
}
.mymodal-center {
    margin-left: 26%;
    margin-top: 3%;
}
.followComClass{
    font-size: 12px !important;
}
.commentHereCls{
    border-radius: 10px !important;
    border-color: #0DD3AF !important;
}
.noRecordCls{
    margin-top: 60px !important;
}
.onRatingCls{
    background-color: #0DD3AF !important;
    color: #f1f1f1 !important;
}
.ratingCls{
    background-color: #f1f1f1 !important;
    color: #000 !important;
}
.comImgClass{
    margin-left:-1px !important;
}
.imgHeight{
    height: 129px !important;
}
@media (min-width: 365px) and (max-width: 411px) {
    .imgHeight{
        height: 131px !important;
    }
}

@media (min-width: 100px) and (max-width: 360px) {
    .imgHeight{
        height: 122px !important;
    }
}
.backgroundImage{
    position: absolute;
  opacity: .4;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.company-header-fixed{
    position: fixed;
    width: 50%;
    z-index: 1030; 
}
.header-top-fixed{
    position: fixed;
    top: 5%;
    width: 50%;
    z-index: 1030;
	box-shadow: 0 0px 7px 0 rgb(0 0 0 / 6%), 0 3px 25px 0 rgb(0 0 0 / 8%);
}
.tab-fixed {
    position: fixed;
    top: 25%;
    width: 50%;
    z-index: 1030;
}
.pt-20{
   padding-top: 15rem !important;
}

.companyLogo{
    position: absolute;
    display: block;
    max-width: 87%;
    max-height: 82%;
    left: 16px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto
}
.headerTxtStyle{
    width : 80%;
    background: crimson;
    align-items: center;
    justify-content: center;
}
.headerBox p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.headerLine{
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    line-clamp: 1;
    font-size: 15px;
  
}

.follownbtn {
    background: linear-gradient(45deg, #00b9c0, #08c797);
    z-index: 99999;
    border-radius: 17px;
    width: 128px;
}

.nav-pills-tabs .nav-link.active {
    color: #343a40!important;
    font-weight: bold;
    /* background: linear-gradient(45deg, #02e5ac, #08c797); */
    margin-left: 0px;
    margin-right: 3px;
   border-bottom: 5px solid #1dc8cd;
}

.company-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 1.25rem;
}

.addressText{
    height: 58px;
    font-size: 15px;
    word-break: break-all;
    overflow-y: scroll;
}
.addressText::-webkit-scrollbar{
    width: 3px;
    background-color: #f3f3f3;
}
/* ------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------FOR RESPONSIVNESS----------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------- */


@media only screen and (min-width:100px) and (max-width:320px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width: 27.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 33% !important;
        width: 100%;
        font-size: 12px;
    }
}
@media only screen and (min-width:322px) and (max-width:360px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width: 27.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 29% !important;
        width: 100%;
        font-size: 12px;
    }
}
@media only screen and (min-width:365px) and (max-width:375px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width: 27.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 28% !important;
        width: 100%;
        font-size: 12px;
    }
}
@media only screen and (min-width:376px) and (max-width:411px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width: 33.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 26% !important;
        width: 100%;
        font-size: 12px;
    }
}
/* Target Portrait and Landscape */
@media only screen 
  and (device-width: 411px) 
  and (device-height: 823px) 
  and (-webkit-device-pixel-ratio: 3.5)  {
    .tab-fixed {
        top: 23% !important;
        width: 100%;
        font-size: 12px;
    }
}

@media only screen and (min-width:411.5px) and (max-width:412px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width:  30.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 21% !important;
        width: 100%;
    }
}
@media only screen and (min-width:413px) and (max-width:414px){
    .addressText{
        height: 78px;
        font-size: 13px;
        word-break: break-all;
    }
    .col-4 {
        max-width: 30.333333% !important;
    }
    .pt-20 {
        padding-top: 15rem !important;
    }
    .tab-fixed {
        top: 25%!important;
        width: 100%;
    }
}
@media only screen  and (max-width:768px){
    .backArrowStyle{
        margin-left: 1px;
    }
    .ShareIconStyle {
        margin-left: 2px;
    }
    .company-header-fixed{
        width: 100%;
    }
    .header-top-fixed{
        width: 100%;
    }
     .width-img{
        width: 100%;
        margin-left: 0px !important;
    }
    .mymodal-center{
        margin-left: 0%;
        margin-top: 3rem;
    }
}
@media only screen and (min-width:767px) and (max-width:1024px){
    .tab-fixed{
        top: 19% !important;
    }
    .pt-20 {
        padding-top: 20rem !important;
    }
}
.empButton{
    border: 2px solid #fff;
    margin-top: -5px;
    border-radius: 24px;
    width: 175px;
}
.webNavBarCls{
background-color: transparent !important;
position: fixed !important;
}
.webNavBarClass{
  background-color: #242424 !important;
  position: fixed !important;
}
.empButton:hover{
  border-color: #00cc99;
}
.borderBottom{
  border-bottom: 2px solid #1dc8cd;
}
.empButton>a:hover{
  color: #00cc99 !important;
}
.credits>a{
  color: #00cc99;
}
.placeHolder::placeholder {
  font-size: 14px;
}
.navBarColor{
  background-color: transparent !important;
  position: fixed !important;
}
.navBarColor1{
background-color: "#242424" !important;
}
.selectHolder{
  font-size: 14px;
}
.credits>a:hover{
  color: #00cc99;
  text-decoration: none;
}
.myNavbarRight {
  font-size: 14px;
  float: right;
}
/* --------------------------------------- For Active Navigation Color ----------------------------------------*/
.active>.nav-link {
	color: #0A9FA5 !important;
}
.myText:hover{
  color: #00cc99 !important;
}
.display-inflex{
  display: inline-flex;
}
body{
  zoom: 100%;
}
#header>.container{
  background: transparent !important;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
a {
  color: #1dc8cd;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #1dc9ce;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}
/*chatbot*/
  iframe#launcher {
    top: 84% !important;
    margin: 9px 3px !important;
  }
  iframe#webWidget {
    top: 10% !important;
    margin-left: 73% !important;
    z-index: 9 !important;
  }
/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: linear-gradient(45deg, #1de099, #1dc8cd);
  color: #fff;
  padding: 2px 20px 8px 20px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  right: 15px;
  bottom: 0;
  transition: none;
}

.back-to-top:focus {
  background: linear-gradient(45deg, #1de099, #1dc8cd);
  color: #fff;
  outline: none;
}
.navbar-toggler-icon::before{
  content:'\2630';
  color: #fff;
  font-size: 24px;
}

.back-to-top:hover {
  background: #1dc8cd;
  color: #fff;
}

/*For modal*/
.myEventDialog{
  margin-left: 23% !important;
  margin-top: 5% !important;
}
.myEventContent{
width: 816px !important;
height: 536px !important;
}
@media only screen and (max-width: 600px){
.myEventDialog{
  margin-left: 6% !important;
}
.myEventContent{
  width: 440px !important;
  height: 536px !important;
}
.placeHolder::placeholder {
  font-size: 12px;
}
.modal-content {
  width: 100% !important;
  left: 0%;
}
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  padding: 00px 0;
  height: 92px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.5s;
  z-index: 997;
}
@media only screen and (max-width:768px){
  #header{
    padding: 0;
    height: 0px;
    background-color: #242424 !important;
  }
  .navbar-brand {
    padding-top: 0rem;
    margin-left: 1rem;
  }
  .navbar-dark .navbar-toggler{
    margin-right: 12px;
  }
  .myInput-group{
    height: 35px !important;
  }
  .cityHeight{
    height: 35px !important;
  }
  .selectHolder {
    font-size: 16px !important;
}
}

#header #logo {
  float: left;
  margin: 0 0 0 0;

}

#header #logo h1 {
  font-size: 36px;
  margin: -4px 0 0 0;
  padding: 0;
  line-height: 1;
  display: inline-block;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

#header.header-fixed {
  /*background: linear-gradient(45deg, #0fcc87, #1dc8cd);*/
  background: linear-gradient(45deg, #272727, #1f1f1f);
  padding: 20px 0;
  height: 72px;
  transition: all 0.5s;
}
a:hover {
    color: #00cc99;
  }
.text-success-gradient{
  color: #1dc8cd;
}
#header .menu a:hover{
color: #1dc8cd;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
#myVideo {
    width: 100%;
    height: 100%;
}

#intro {
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../assets/intro-bg.jpg') center top no-repeat;
  background-size: cover;
  position: relative;
}

#intro .intro-text {
  position: absolute;
  left: 0;
  top: 230px;
  right: 0;
  height: calc(50% - 60px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#intro h3{
  color:white;
}
#intro h2 {
  margin: 30px 0 10px 0;
  padding: 0 15px;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: #fff;
}
#intro h1 {
  margin: 30px 0 10px 0;
  padding: 0 15px;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
  color: #fff;
}
#intro p {
  color: #fff;
  margin-bottom: 20px;
  padding: 0 15px;
  font-size: 24px;
}
#intro .btn-get-started {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0px 11px;
  border-radius: 0px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#intro .btn-get-started:hover {
  color: #1dc8cd;
  background: #fff;
}

#intro .product-screens {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  text-align: center;
  width: 100%;
  height: 23%;
}

/*#intro .product-screens img {
  box-shadow: 0px -2px 19px 4px rgba(0, 0, 0, 0.29);
}
*/
#intro .product-screens .product-screen-1 {
  position: absolute;
  z-index: 30;
  left: calc(50% - 97px);
  bottom: 0;
  top: 3px;
}

#intro .product-screens .product-screen-2 {
  position: absolute;
  z-index: 20;
  left: calc(50% - 303px);
  bottom: 0;
  top: 30px;
}

#intro .product-screens .product-screen-3 {
  position: absolute;
  z-index: 10;
  left: calc(50% - 303px);
  bottom: 0;
  top: 150px;
}
.modal-body{
  position: relative;
    -ms-flex: 1 1 auto;
    width: 100%;
    height: auto;
  }

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */

.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Styling */

.nav-menu a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  outline: none;
}

.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}
.navbar-dark{
  background: linear-gradient(45deg, #272727, #1f1f1f) !important;
}
.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #1dc8cd;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */

#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
}

#mobile-nav-toggle i {
  color: #fff;
}

/* Mobile Nav Styling */

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(52, 59, 64, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #1dc8cd;
}

#mobile-nav ul .menu-item-active {
  color: #1dc8cd;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

/* Sections Header
--------------------------------*/
.fa-check-circle{
      color: #1dc8cd;
}
.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-align: center;
  font-weight: 400;
}

.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: #777;
  font-style: italic;
}

.section-header .section-divider {
  display: block;
  width: 60px;
  height: 3px;
  background: #1dc8cd;
  background: linear-gradient(0deg, #1dc8cd 0%, #55fabe 100%);
  margin: 0 auto;
  margin-bottom: 20px;
}

/* Section with background
--------------------------------*/

.section-bg {
  background: #eff5f5;
}

/* About Us Section
--------------------------------*/

#about {
  padding: 45px 0;
  overflow: hidden;
}

#about .about-img {
  height: 510px;
  overflow: hidden;
}

#about .about-img img {
  margin-left: -15px;
  max-width: 100%;
}

#about .content .h2 {
  color: #333;
  font-weight: 300;
  font-size: 24px;
}

#about .content h3 {
  color: #777;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

#about .content p {
  line-height: 26px;
}

#about .content p:last-child {
  margin-bottom: 0;
}

#about .content i {
  font-size: 20px;
  padding-right: 4px;
  color: #1dc8cd;
}

#about .content ul {
  list-style: none;
  padding: 0;
}

#about .content ul li {
  padding-bottom: 10px;
}

/* Product Featuress Section
--------------------------------*/

#features {
  background: #fff;
  padding: 45px 0 0 0;
  overflow: hidden;
}

#features .features-img {
  text-align: center;
  padding-top: 20px;
}

#features .features-img img {
  max-width: 100%;
}

#features .box {
  margin-bottom: 15px;
  text-align: center;
}

#features .icon {
  margin-bottom: 10px;
}

#features .icon i {
  color: #666666;
  font-size: 40px;
  transition: 0.5s;
}

#features .icon i:before {
  background: #1dc8cd;
  background: linear-gradient(45deg, #1dc8cd 0%, #55fabe 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-success{
  background-color: rgb(0, 204, 153) !important;
  border: 0 !important;
}
#features .title {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 22px;
}

#features .title a {
  color: #111;
}

#features .description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
}

#features .section-description {
  padding-bottom: 10px;
}

/* panel Featuress Section
--------------------------------*/

#panel {
  background: #fff;
  padding: 45px 0 0 0;
  overflow: hidden;
}

#panel .features-img {
  text-align: center;
  padding-top: 20px;
}

#panel .features-img img {
  max-width: 100%;
}

#panel .box {
  margin-bottom: 15px;
  text-align: center;
}

#panel .icon {
  margin-bottom: 10px;
}

#panel .icon i {
  color: #666666;
  font-size: 40px;
  transition: 0.5s;
}

#panel .icon i:before {
  background: #1dc8cd;
  background: linear-gradient(45deg, #1dc8cd 0%, #55fabe 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#panel .title {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 22px;
}

#panel .title a {
  color: #111;
}

#panel .description {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
}

#panel .section-description {
  padding-bottom: 10px;
}
/* Product Advanced Featuress Section
--------------------------------*/

#advanced-features {
  overflow: hidden;
}

#advanced-features .features-row {
  padding: 45px 0 30px 0;
}

#advanced-features h2 {
  font-size: 26px;
  font-weight: 700;
  color: #000;
}

#advanced-features h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: italic;
  color: #999;
}

#advanced-features p {
  line-height: 24px;
  color: #777;
  margin-bottom: 30px;
}

#advanced-features i {
  color: #666666;
  font-size: 64px;
  transition: 0.5s;
  float: left;
  padding: 0 15px 0px 0;
  line-height: 1;
}

#advanced-features i:before {
  background: #1dc8cd;
  background: linear-gradient(45deg, #1dc8cd 0%, #55fabe 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#advanced-features .advanced-feature-img-right {
  max-width: 100%;
  float: right;
  padding: 0 0 30px 30px;
}

#advanced-features .advanced-feature-img-left {
  max-width: 100%;
  float: left;
  padding: 0 30px 30px 0;
}

/* Post To Action Section
--------------------------------*/
#post-to-action {
  overflow: hidden;
  background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)), url('../assets/post-to-action-bg.jpg') fixed center center;
  background-size: cover;
  padding: 80px 0;
}
#post-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#post-to-action .cta-text {
  color: #fff;
}

#post-to-action .cta-btn {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 25px;
  transition: background 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#post-to-action .cta-btn:hover {
  background: transparent;
  border: 2px solid #fff;
}
/* Call To Action Section
--------------------------------*/

#call-to-action {
  overflow: hidden;
  background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)), url('../assets/call-to-action-bg.jpg') fixed center center;
  background-size: cover;
  padding: 80px 0;
}

#call-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#call-to-action .cta-text {
  color: #fff;
}

#call-to-action .cta-btn {
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 25px;
  transition: background 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#call-to-action .cta-btn:hover {
  background: #1dc8cd;
  border: 2px solid #1dc8cd;
}

/* More Features Section
--------------------------------*/

#more-features {
  padding: 60px 0 60px 0;
  overflow: hidden;
}

#more-features .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  transition: 0.4s;
}

#more-features .icon {
  float: left;
}

#more-features .icon i {
    color: #666666;
    font-size: 71px;
    transition: 0.5s;
    line-height: 0;
    margin-top: 34px;
    margin-left: -13px;
}

#more-features .icon i:before {
  background: #1dc8cd;
  background: linear-gradient(45deg, #1dc8cd 0%, #55fabe 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#more-features h4 {
  margin-left: 100px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#more-features h4 a {
  color: #111;
}

#more-features p {
  font-size: 11px;
  margin-left: 100px;
  margin-bottom: 0;
  line-height: 15px;
}

/* Clients Section
--------------------------------*/

#clients {
  padding: 30px 0;
  background: #fff;
  overflow: hidden;
}

#clients img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

/* Pricing Section
--------------------------------*/

#pricing {
  padding: 60px 0 60px 0;
  overflow: hidden;
}

#pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

#pricing h3 {
  font-weight: 400;
  margin-bottom: 15px;
  font-size: 28px;
}

#pricing h4 {
  font-size: 46px;
  color: #1dc8cd;
  font-weight: 300;
}

#pricing h4 sup {
  font-size: 20px;
  top: -20px;
}

#pricing h4 span {
  color: #bababa;
  font-size: 20px;
}

#pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

#pricing ul li {
  padding-bottom: 12px;
}

#pricing ul i {
  color: #1dc8cd;
  font-size: 18px;
  padding-right: 4px;
}

#pricing .get-started-btn {
  background: #515e61;
  display: inline-block;
  padding: 6px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;
}

#pricing .featured {
  border: 2px solid #1dc8cd;
}

#pricing .featured .get-started-btn {
  background: linear-gradient(45deg, #1de099, #1dc8cd);
}

/* Frequently Asked Questions Section
--------------------------------*/

#faq {
  padding: 55px 0;
  overflow: hidden;
}

#faq #faq-list {
  padding: 0;
  list-style: none;
}

#faq #faq-list li {
  border-bottom: 1px solid #ddd;
}

#faq #faq-list a {
  padding: 18px 0;
  display: block;
  position: relative;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
  line-height: 1;
  font-weight: 300;
  padding-right: 20px;
}

#faq #faq-list i {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 16px;
}

#faq #faq-list p {
  margin-bottom: 20px;
}

#faq #faq-list a.collapse {
  color: #1dc8cd;
}

#faq #faq-list a.collapsed {
  color: #000;
}

#faq #faq-list a.collapsed i::before {
  content: "\f2c7" !important;
}

/* Our Team Section
--------------------------------*/

#team {
  padding: 60px 0;
  overflow: hidden;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
}

#team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
}

#team .member .pic img {
  max-width: 100%;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  color: #b3b3b3;
}

#team .member .social a:hover {
  color: #1dc8cd;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Gallery Section
--------------------------------*/

#gallery {
  background: #fff;
  padding: 0px 0 0 0;
  overflow: hidden;
}

#gallery .container-fluid {
  padding: 0px;
}

#gallery .gallery-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all ease-in-out 0.4s;
}

#gallery .gallery-item {
  overflow: hidden;
  position: relative;
  padding: 0;
  vertical-align: middle;
  text-align: center;
}

#gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
  width: 100%;
  height: 360px;
}

#gallery .gallery-item:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#gallery .gallery-item:hover .gallery-overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
}

/* Contact Section
--------------------------------*/

#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 70px 0;
  /* background: linear-gradient(rgba(29, 200, 205, 0.65), rgba(29, 205, 89, 0.2)), url(/static/media/call-to-action-bg.4d43b3d1.jpg) fixed center center; */
  background-size: cover;
  background: url(../assets/footer.png) fixed center center;

}
#contact .contact-about h3 {
  font-size: 36px;
  margin: 0 0 10px 0;
  padding: 0;
  line-height: 1;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #1dc8cd;
}

#contact .contact-about p {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Work Sans', sans-serif;
  color: #888;
}


#contact .social-links {
  padding-bottom: 20px;
  padding-top: 20px;

}

#contact .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #fff;
  color: #15CAA8; /*#1dc8cd*/
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  border: 1px solid #15CAA8;
}

#contact .social-links a:hover {
  background: #15CAA8;
  color: #fff;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  font-size: 32px;
  color: #15CAA8;
  float: left;
  line-height: 1;
}

#contact .info p {
  padding: 0 0 10px 23px;
  line-height: 28px;
  font-size: 14px;
}
#contact .info p a{
  color: white;
}

#contact .form #sendmessage {
  color: #1dc8cd;
  border: 1px solid #1dc8cd;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#contact .form button[type="submit"] {
  background: linear-gradient(45deg, #1de099, #1dc8cd);
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
}

#contact .form button[type="submit"]:hover {
  cursor: pointer;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #271E1E;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  color: #fff;
  font-size: 14px;
}

#footer .credits {
  font-size: 13px;
  color: #fff;
}

#footer .footer-links a {
  color: #fff;
  padding-left: 15px;
}

#footer .footer-links a:first-child {
  padding-left: 0;
}

#footer .footer-links a:hover {
  color: #1dc8cd;
}
.naya-box{
  display: inline-flex;
}

@media only screen and (max-width: 600px) {
 #intro .mobile-res{
    padding: 0px 10px !important;
  }
  .box-text{
    font-size: 14px;
  }
  .box-content{
    font-size: 14px;
  }
  #intro h2 {
    font-size: 37px;
  }
  #intro h1 {
    /* margin: 60px 0 10px 0; */
    font-size: 30px;
}
#header>.container{
  background: #242424 !important;
}

.searchButton{
  margin-left: 17% !important;
  padding-left: 11% !important;
}

}
@media (min-width: 769px) {
  #features .features-img {
    padding-top: 120px;
    margin-top: -200px;
  }

  #call-to-action .cta-btn-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 1025px) {
  #intro {
    background-attachment: fixed;
  }
}
@media (min-width: 1680px) {
  #intro {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
    margin-top: 0;
  }
  .modal-content {
    width: 100% !important;
    left: 0%;
}
  .placeHolder::placeholder {
    font-size: 12px;
  }
  #header #logo img {
    max-height: 48px;
    margin-top: 7px;
    width: 170px;
  }
  #intro h2 {
    font-size: 28px;
    line-height: 36px;
  }

  #intro p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  #nav-menu-container {
    display: none;
  }

  #mobile-nav-toggle {
    display: inline;
  }

  #about .about-img {
    height: auto;
  }

  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }

  #advanced-features .advanced-feature-img-right,
  #advanced-features .advanced-feature-img-left {
    max-width: 50%;
  }

  #faq #faq-list a {
    font-size: 16px;
  }

  #faq #faq-list i {
    top: 13px;
  }
}

.myProfile .myImageProfile {
  position: absolute;
  opacity: .4;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("../assets/iconwallpaper.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.ndfHFb-c4YZDc-Wrql6b{
  margin: 3% !important;
}
.resumeCls{
  height: 45px !important;
}
.qrWallCls{
  background-color: #ffffff !important;
}
.viewResume{
   margin-top: 12px !important;
  padding-top: .8rem !important;
}
.jsName{
  font-size: 24px !important;
  word-break: break-all !important;
}
.verifyText{
  font-size: 13px !important;
}
.profile-bg-success{
height: 26px !important;
border-top-left-radius: 1.25rem !important;
border-top-right-radius: 1.25rem !important;
}
.curWork{
  border-radius: 15px !important;
}
.aboutMeText{
  border-radius: 10px !important;
}

.ml-6{
  margin-left: 4rem !important;
}

.disablePointer{
  cursor: default !important;
}
.modal-shift-center{
    width: 100% !important;
    left: 0% !important;
    border-radius: 20px !important;
}
.text-success{
  color: #00cc99 !important;
}
.rc-slider-handle{
  background-color: #00cc99 !important;
  border-color: #00cc99 !important;
}
.react-player>video {
  width: 100% !important;
  height: 87% !important;
  border-radius: 17px !important;
  object-position: top;
}

.editButtonTop {
  margin-top: 89%;
  margin-left: -19%;
  z-index: 99;
}

.border-top {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.circleIm {
  margin-left: 35% !important;
  margin-top: -5% !important;
}

.circleSet {
  margin-left: 34% !important;
  margin-top: -8%;
  margin-bottom: 13% !important;
}

.menuFixedProfile {
  margin-top: 4%;
  margin-bottom: -2%;
}

.buttonRounded {
  border-radius: 10px;
}


.statusDiv {
  margin-top: -8%;
}

.pdEdit {
  margin-top: 3px !important;
}

.myProfile {
  position: relative;
  z-index: 1;
}

.loaderWhite {
  margin-left: 40% !important;
}

.profileImage {
  width: 260px !important;
  height: 260px;
  border-radius: 150px;
  margin-top: 6%;
}
/* For Edit profile date picker */
.react-datepicker-wrapper{
  width: 100%;
}
/* End */
.bg-white{
  color: #fff ;
}
.w-30 {
  width: 43% !important;
}

.w-40 {
  width: 45.666667%;
}

.plus-icon {
  vertical-align: middle;
  border-style: none;
  height: 20px;
  margin: 6px;
}

.patchPills {
  width: 11.1%;
  height: 80px;
  background-color: #fff;
  border-radius: 74px;
  font-size: 10px;
  margin-right: 2%;
  padding: 0px;
}

.shortlistedName {
  margin-left: -15%;
}

.react-sweet-progress-line-inner-status-active {
  position: relative;
  min-height: 10px;
  border-radius: 100px;
  background-color: #00cc99;
  transition: width 0.3s ease;
}

.react-sweet-progress-line {
  border: 1.4px solid rgb(12, 159, 165);
}

.numberText {
  color: #000;
  font-size: 20px;
}

.statusText {
  color: #000;
  font-weight: 500;
  font-size: 10px;
}

.mb-5 {
  margin-bottom: 1rem!important;
}

.file {
  right: 0;
}

.fa-1x {
  font-size: 1.5em;
}

.editExp {
  margin-left: 15% !important;
}

.creditClass {
  margin-top: 3%;
  cursor: pointer;
  height: 140px !important;
}


/* For  menu */

.menu {
  position: absolute;
  display: none;
  width: 39%;
  height: auto;
  right: 0%;
  top: 10%;
  background-color: rgba(13, 211, 175, 0.8);
  z-index: 2;
  cursor: pointer
}

.menuA {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 20px;
  padding-left: 10px;
}

.menuUl {
  padding: 0;
  margin: 0;
}

.menuUl>li {
  font-size: 15px;
  border-bottom: 1px solid #fff;
  transition: all 0.25s ease;
  animation: fadeInRight .35s ease forwards;
  opacity: 0;
  list-style-type: none;
}

.menuUl>li:hover {
  opacity: 0.8;
  transition: all 0.25s ease;
  background: rgba(13, 211, 175, 0.92);
  cursor: pointer;
  text-decoration: none;
}

.menuUl>li>a {
  text-decoration: none !important;
}
.shareMenuButoon {
  text-decoration: none !important;
}

.menuList a:hover {
  color: #fff;
  text-decoration: none !important;
}

.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  transform: translateX(0);
}
/* for resume builder button text */
.resumeTextBtn{
  white-space: nowrap;
 }
 .react-date-picker__wrapper{
  border: 0 !important;
}
.react-date-picker{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 2px !important;
  padding-right: 0px !important;
}
/* For making profile page responsive */

@media only screen and (max-width: 600px) {
  .ml-6 {
    margin-left: 4rem !important;
    margin-right: 3rem !important;
  }
  .pdEdit {
    margin-top: 3px !important;
  }
  .resumeDisplay {
    height: 200px;
  }
  .resumeImage {
    height: auto;
    max-height: 350px;
  }
  .circleSet {
    margin-left: 37% !important;
  }

  .menuFixedProfile {
    margin-top: 0%;
    margin-bottom: 0%;
    overflow-x: hidden;
  }
  .modal-body {
    width: 100%;
    height: auto !important;
  }
  .editButtonTop {
    margin-top: 82%;
    margin-left: -22%;
    z-index: 99;
  }
  .menu {
    position: absolute;
    display: none;
    width: 40%;
    height: auto;
    right: 0%;
    top: 10%;
    background-color: rgb(0 170 153 / 0.8) !important;
    z-index: 2;
    cursor: pointer;
  }
}

@media only screen and (min-width:100px) and (max-width:468px) {
  .circleProfile {
    margin-top: -55%;
    margin-left: 27%;
  }
  .menu {
    position: fixed !important;
    width: 41% !important;
    height: calc(1366px - 100vh) !important;
    right: 0% !important;
    top: 0% !important;
    background-color: rgb(0 170 153 / 0.8) !important;
    z-index: 2 !important;
    cursor: pointer !important;
  }
}

@media (min-width: 992px) {
  .resumeDisplay {
    height: 197px;
  }
}

@media (max-width: 767px) {
  .numberText {
    color: #000;
    font-size: 18px;
  }
  .statusText {
    color: #000;
    font-weight: 500;
    font-size: 7px;
  }
  .editExp {
    margin-left: 8% !important;
  }
  .nabSpace {
    margin-bottom: 4rem!important;
  }
  .profileImage {
    width: 100% !important;
    height: 120px;
    border-radius: 150px;
    margin-top: 16%;
    margin-left: 6%;
  }
  .patchPills {
    width: 13%;
    height: 55px;
    background-color: #fff;
    border-radius: 74px;
    font-size: 4px;
    margin-right: 2%;
    padding: 0px;
  }
  .creditClass {
    margin-top: 3%;
    cursor: pointer;
    height: 65px !important;
  }
  .fontProgress {
    font-size: 9px !important;
  }
  .fontProgressPer {
    font-size: 17px !important;
  }
  .display-1 {
    font-size: 4rem !important;
    font-weight: 300;
    line-height: 1.2;
  }
  .resumeFont {
    font-size: 13px;
    font-weight: 900;
  }
}

@media (max-width: 400px) {
  .numberText {
    color: #000;
    font-size: 17px;
  }
  .statusText {
    color: #000;
    font-weight: 500;
    font-size: 7px;
  }
  .patchPills {
    width: 13%;
    height: 51px;
    background-color: #fff;
    border-radius: 74px;
    font-size: 6px;
    margin-right: 2%;
    padding: 0px;
  }
  .editExp {
    margin-left: 8% !important;
  }
  .nabSpace {
    margin-bottom: 4rem!important;
  }
  .statusDiv {
    margin-top: -5%;
  }
  .profileImage {
    width: 100% !important;
    height: 100px;
    border-radius: 150px;
    margin-top: 16%;
    margin-left: 6%;
  }
  .fontProgress {
    font-size: 9px !important;
  }
  .fontProgressPer {
    font-size: 17px !important;
  }
}

.bg-dark3 {
  background-color: #000 !important;
  margin-left: 22% !important;
}

@media only screen and (max-width: 600px) {
  .bg-dark3 {
    background-color: #000 !important;
    margin-left: 5% !important;
  }
}
.textSetting{
  font-size: 16px !important;
}
.loginBackground{
    background-image: url('../assets/iconwallpaper.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
    transform: scale(1.1);
    animation: pulse 6s infinite;
}
.termText{
    margin-top: -4px;
    font-size: 15px;
}

.kep-login-facebook.metro::before{
    content: url("../assets/fbIcon.png");
    margin-right: 4px;
}
.kep-login-facebook{
    font-weight: 500!important;
}
.kep-login-facebook.metro{
    border-radius: 34px !important;
    background-image: linear-gradient(to left bottom, #133b90, #0f3382, #0a2c75, #052568, #011e5b) !important;
    border: none !important;
    padding: 12px !important;
    width: 100% !important;
}
.danger-gradient{
    border-radius: 34px;
    background-image: linear-gradient(to left bottom, #b23121, #ba3627, #c33c2c, #cb4132, #d44638) !important;
    border: none;
    padding: 12px;
}
.primary-gradient {
    background-image: linear-gradient(to left bottom,#133b90,#0f3382,#0a2c75,#052568,#011e5b)!important;
   
    border: 0;
    border-radius: 24px;
    padding: 12px;
}


.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  margin-top: 11% !important;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

#icon {
    width: 22%;
    margin-bottom: 3%;
}

@media only screen and (max-width:768px){
    .google-text{
        font-size: 11px;
    }
    .facebook-text{
        font-size: 10px;
    
    }
    .kep-login-facebook.metro {
        font-size: 11px;
    }
    .kep-login-facebook.metro::before{
        content: url("../assets/fbIcon.png");
        margin-right: 4px;
    }
    .fa-google {
        font-size: 15px;
    }
}
@media (max-width: 575.98px)  {
    .loginBackground{
        height: 613px;
    }
    #formContent {
        margin-top: 38% !important;
    }
    .myCard{
        margin-top:-105% !important;
    }
    .termText{
        margin-top: -1px;
        word-break: break-all;
        font-size: 11.5px;
    }
}
@media (max-width:380px){
    .loginBackground{
        height: 613px;
    }
    .myCard{
        margin-top: -138% !important;
    }
}
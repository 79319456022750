.padding-bottom-4 {
	padding-bottom: 2.8rem!important;
}
/* New layout jobs */
.section-1{
	box-shadow: 0 0px 7px 0 rgb(0 0 0 / 6%), 0 3px 25px 0 rgb(0 0 0 / 8%);
    border-radius: 15px;
}
.word-break{
	word-wrap: break-word;
}
.jobProCls{
	font-size: 15px !important;
}
.logoSizeCls{
	font-size: 18px !important;
}
.googleMapCls{
	height: 300px !important;
	width: 100% !important;
}
.dirImg{
	width: 44px !important;
}
.notInterCls{
	height: 45px !important;
	z-index: 99 !important;
}
.whatsWrongCls{
	font-size: 16px !important;
}
.regAndApplyCls{
	height: 40px !important;
}
.googleLogoCls{
position: absolute !important ;
width: 25px !important;
height: 25px !important;
top: 100% !important;
left: 100% !important;
transform: translate(-50%, -100%) !important
}
.section-2{
	/* box-shadow: 0 0px 7px 0 rgb(0 0 0 / 6%), 0 3px 25px 0 rgb(0 0 0 / 8%); */
	border-radius: 15px;
	max-width: 63.666667% !important;
}
.saveIcon-view{
	margin-top: -6px;
}
/* New layout ends */
.form-control[readonly] {
	background-color: transparent;
}
.header-top{
	position: fixed;
    top: 0;
    right: 0;
    left: 25% !important;
    z-index: 1030;
}
.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
	margin: 0 auto;
}

.fixed-bottom1 {
	position: fixed;
	right: 0;
	bottom: 40px;
	left: 40%;
	z-index: 1030;
	margin: 0 auto;
}

.headingColor {
	color: #323643;
	font-weight: 900 !important;
	font-size: 14px;
}

.No-Pading {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.contentTextColor {
	color: #757575;
}

.companyNameColor {
	color: #0DD3AF;
}

.verticalText {
	/* display: inline-block;
    vertical-align: middle; */
	margin-top: auto;
	margin-bottom: auto;
}

.applyFont {
	font-size: 14px;
}

.loaderDetail {
	/* 757575 */
	background: no-repeat center center;
	height: 100px;
	width: 100%;
	position: fixed;
	z-index: 1000;
	/* left: 50%; */
	top: 40%;
	margin: -25px 0 0 -25px;
}

.btnTopCard {
	width: fit-content;
}
.new-bookmark{
	width: 29px;
	height: 30px;
	margin-top: 0px;
	margin-left: 18px;
	padding-top: 3px;
	padding-left: 6px !important;
}
.compName a {
	text-decoration: underline;
	color: #00cc99 !important;
	transition: 0.5s;
	font-weight: 900 !important;
	font-family: 'Work Sans', sans-serif;
}

.jobs-modal-view {
	width: 80%;
	left: 0%;
	border-radius: 20px;
}

.callHRSection {
	width: 100% !important;
	left: 0% !important;
	border-radius: 20px !important;
}

.mapcontent {
	color: #00D0B2;
	background-color: #ffffffcf;
	width: max-content;
	padding: 0px;
	margin-top: -45px;
	margin-left: -65px;
}

.mapText {
	font-size: 16px !important;
}

.circle {
	margin-left: 31% !important;
	width: 60px !important;
	height: 60px !important;
	margin-top: 5%;
}

.circleImage {
	width: 30px;
	height: 30px;
	margin: -6px;
}

.SaveUnsaveHeader {
	height: 55px !important;
}

@media only screen and (min-width:100px) and (max-width:321px) {
	.callHRMobile {
		font-size: 7px !important;
	}
	
	.circle {
		margin-left: 22% !important;
		width: 60px !important;
		height: 60px !important;
		margin-top: 5%;
	}
	.circleImage {
		width: 30px;
		height: 30px;
		margin: -6px;
	}
	.callDetails {
		font-size: 14px !important;
		padding-top: 9px;
	}
	.dirImg {
		margin-top: 79%;
	}
	.fontqualification{
		font-size: 0.9rem !important;
	}
	.jobNameLimit {
		font-size: 15px !important;
	}
}

@media only screen and (max-width:768px) {
	.fixed-bottom1 {
		left: 68%;
	}
	.header-top{
		position: fixed;
		top: 0;
		right: 0;
		left: 0% !important;
		z-index: 1030;
	}
	.jobNameLimit {
		width: 228px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 18px;
	}
}

@media only screen and (min-width:325px) and (max-width:365px) {
	.callHRMobile {
		font-size: 9px !important;
	}
	.circle {
		margin-left: 22% !important;
		width: 60px !important;
		height: 60px !important;
		margin-top: 5%;
	}
	.circleImage {
		width: 30px;
		height: 30px;
		margin: -6px;
	}
	.dirImg {
		margin-top: 70%;
	}
	.fontqualification{
		font-size: 0.9rem !important;
	}
}

@media only screen and (min-width:370px) and (max-width:400px) {
	.callHRMobile {
		font-size: 10px !important;
	}
	.circle {
		margin-left: 22% !important;
		width: 60px !important;
		height: 60px !important;
		margin-top: 5%;
	}
	.circleImage {
		width: 30px;
		height: 30px;
		margin: -6px;
	}
	.dirImg {
		margin-top: 68%;
	}
	.fontqualification{
		font-size: 0.9rem !important;
	}
}

@media only screen and (min-width:410px) and (max-width: 600px) {
	.btnTopCard {
		width: fit-content;
		font-size: 11px;
	}
	.rowAlignTop {
		margin-top: 0px;
	}
	.dirImg {
		margin-top: 62%;
	}
	.jobs-modal-view {
		width: 75%;
		left: 0% ;
		border-radius: 20px;
	}
	.callHRSection {
		width: 100% !important;
		left: 0% !important;
		/* margin-top: 36% !important; */
		border-radius: 20px !important;
	}
	.modalwidth {
		width: 96% !important;
		left: 2% !important;
		margin-top: 36% !important;
		border-radius: 10px !important;
	}
	.circle {
		margin-left: 23% !important;
		width: 60px !important;
		height: 60px !important;
		margin-top: 5%;
	}
	.circleImage {
		width: 30px;
		height: 30px;
		margin: -6px;
	}
	.dirImg {
		margin-top: 62%;
	}
}

.bg-dark1 {
	background-color: #000 !important;
	margin-left: 22% !important;
}
.bg-close{
	background-color:  #dc3545 !important;
}

.modalwidth {
	width: 96% !important;
	left: 2% !important;
	margin-top: 36% !important;
	border-radius: 10px !important;
}

.roundedModal {
	border-radius: 12px !important;
}
.newImage {
	position: absolute;
	display: block;
	max-width: 100%;
	max-height: 85%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}
.lead{
	height: 40px !important;
}
.dirImg {
	margin-top: 31%;
	position: absolute;
	z-index: 99;
	margin-left: 2%;
}
.badge-secondary{
    background-color:#F7F8FA !important;
    color: #8B9CA8;
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
	.jobs-modal-view{
		margin-left: 2% !important;
	}
}
@media only screen and (max-width: 640px) and (min-width: 360px) {
	.jobs-modal-view{
		margin-left: 2% !important;
	}
}

@media only screen and (max-width:767px) {
	.bg-dark1 {
		background-color: #000 !important;
		margin-left: 2% !important;
	}
	.font-10{
		font-size: 10px !important;
	}
	.HrModal{
		width: 300px !important;
	}
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
	.jobs-modal-view{
		margin-left: 8% !important;
	}
}
	
	
	
@media only screen and (max-width:600px) {
	.word-break{
		word-wrap: break-word;
	}
	.callHrBlock {
		position: fixed;
		bottom: 0;
		margin-left: 0%;
		height: 50px;
	}
	.rowAlignTop {
		margin-top: 0px;
	}
	.shareButtons {
		margin-left: 4px;
	}
	.secondSecJd {
		padding-right: 0px ! important;
	}
	.firstSec {
		margin-right: 0px ! important;
		margin-left: 0px ! important;
	}
	.appButton {
		margin-left: -70% !important;
	}
	.fsize {
		font-size: 8.5px !important;
	}
	.dirImg {
		margin-top: 61%;
	}
}


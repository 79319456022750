.bg-height {
    margin-left: 25%;
    position: fixed;
    height: 100vh;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.cityOptionCls{
    background: #007bff !important;
    color: white !important;
}
.fixed-top1 {
    position: fixed;
    top: 0;
    right: 0;
    left: 0%;
    z-index: 1030;
}
.mapSearchBar {
    position: absolute;
}
.leaflet-container{
    height: 760px !important;
}
button.gm-fullscreen-control {
    display: none;
}

div.gmnoprint {
    display: none;
}

.list-button {
    position: relative;
    border-radius: 20px;
    margin-top: -70%;
    width: 98px;
    text-transform: uppercase;
    margin-left: 0%;
    z-index: 9999;
}


.fixed-top1{
    display: block !important;
}

/* Map */

.gm-style-iw.gm-style-iw-c {
    padding-right: 12px !important;
    padding-bottom: 9px !important;
    background-image: linear-gradient(to left, #00d5ae, #00b9c0) !important;
}

.gm-style-iw-d {
    overflow: auto !important;
}


/* Map end */
/* For info start */

.leaflet-popup-content-wrapper {
    background-color: #079da3 !important;
}
.leaflet-popup-content>a{
text-decoration:none !important;
    color: #FFF !important;
}
/* For info color end */

.leaflet-container {
    height: 500px;
    width: auto;
}

.markercluster-map {
    height: 500px;
}

.marker-cluster-small {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-small div {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-medium {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-medium div {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-large {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-large div {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-small {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-small div {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-medium {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-medium div {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-large {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-large div {
    background-color: #079da3;
    color: #fff;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif
}

.marker-cluster span {
    line-height: 30px;
    margin-left: -4px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in
}

.leaflet-cluster-spider-leg {
    -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
    -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in
}

.bg-height {
    margin-left: 25%;
    position: fixed;
    height: 100vh;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.fixed-top1 {
    position: fixed;
    top: 0;
    right: 0;
    left: 0%;
    z-index: 1030;
}
.mapSearchBar {
    position: absolute;
}
.leaflet-container{
    height: 760px !important;
}
button.gm-fullscreen-control {
    display: none;
}

div.gmnoprint {
    display: none;
}

.list-button {
    position: fixed;
    border-radius: 20px;
    margin-top: -22%;
    width: 98px;
    text-transform: uppercase;
    margin-left: -2%;
    z-index: 999;
}

.mapCards{
    padding-right: 6% !important;
}

.fixed-top1{
    display: block !important;
}
.leaflet-top.leaflet-left {
    left: 92% !important;
    top: 60% !important;
}
/* Map */

.gm-style-iw.gm-style-iw-c {
    padding-right: 12px !important;
    padding-bottom: 9px !important;
    background-image: linear-gradient(to left, #00d5ae, #00b9c0) !important;
}

.gm-style-iw-d {
    overflow: auto !important;
}


/* Map end */
/* For info start */

.leaflet-popup-content-wrapper {
    background-color: #079da3 !important;
}
.leaflet-popup-content>a{
text-decoration:none !important;
    color: #FFF !important;
}
/* For info color end */

.leaflet-container {
    height: 500px;
    width: auto;
}

.markercluster-map {
    height: 500px;
}

.marker-cluster-small {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-small div {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-medium {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-medium div {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-large {
    background-color: #079da3;
    color: #fff;

}

.marker-cluster-large div {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-small {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-small div {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-medium {
    background-color: #079da3;
    color: #fff;

}

.leaflet-oldie .marker-cluster-medium div {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-large {
    background-color: #079da3;
    color: #fff;
}

.leaflet-oldie .marker-cluster-large div {
    background-color: #079da3;
    color: #fff;
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif
}

.marker-cluster span {
    line-height: 30px;
    margin-left: -4px;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in
}

.leaflet-cluster-spider-leg {
    -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
    -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in
}
.pin-center {
    position: absolute;
    z-index: 999;
    margin-top: -53% !important;
    margin-left: 5% !important;
}
@media only screen and (max-width:768px) {
    .bg-height {
        margin-left: 0%;
        padding-left: 0%;
    }
    
    .carousel.carousel-slider {
        width: 110% !important;
        margin-left: -5%;
    }
}
@media only screen and (min-width:100px) and (max-width:320px){
    .list-button {
        margin-top: -104%;
        font-size: 12px;
        position: fixed;
        margin-left: -14%;
    }
    .pin-center {
        position: absolute;
        z-index: 999;
        margin-top: -158% !important;
        margin-left: -5% !important;
    }
    .leaflet-top.leaflet-left {
        left: 82% !important;
        top: 58% !important;
    }
}
@media only screen and (min-width:325px) and (max-width:360px){
    .list-button {
        margin-top: -74%;
        font-size: 12px;
        position: fixed;
        margin-left: -14%;
    }
    .pin-center {
        position: absolute;
        z-index: 999;
        margin-top: -134% !important;
        margin-left: -5% !important;
    }
    .leaflet-top.leaflet-left {
        left: 82% !important;
        top: 58% !important;
    }
}
@media only screen and (min-width:365px) and (max-width:375px){
    .list-button {
        margin-top: -64%;
        font-size: 12px;
        position: fixed;
        margin-left: -13%;
    }
    .pin-center {
        position: absolute;
        z-index: 999;
        margin-top: -125% !important;
        margin-left: -5% !important;
    }
    .leaflet-top.leaflet-left {
        left: 82% !important;
        top: 58% !important;
    }
    
}
@media only screen and (min-width:380px) and (max-width:411px){
    .list-button {
        margin-top: -42%;
        font-size: 12px;
        position: fixed;
        margin-left: -11%;
    }
    .pin-center {
        position: absolute;
        z-index: 999;
        margin-top: -100% !important;
        margin-left: -5% !important;
    }
    .leaflet-top.leaflet-left {
        left: 82% !important;
        top: 70% !important;
    }
}
@media only screen and (min-width:412px) and (max-width:414px){
    .list-button {
        margin-top: -43%;
        font-size: 12px;
        position: fixed;
        margin-left: -11%;
    }
    .pin-center {
        position: absolute;
        z-index: 999;
        margin-top: -101% !important;
        margin-left: -5% !important;
    }
    .leaflet-top.leaflet-left {
        left: 82% !important;
        top: 58% !important;
    }
}
@media only screen and (max-width:768px) {
    .bg-height {
        margin-left: 0%;
        padding-left: 0%;
    }
}